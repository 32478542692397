import React from 'react';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';
import { handleCallNewsApp } from 'ThreeTerminal_utils';

class BottomSlide extends React.PureComponent {
    callupApp = () => {
        const nodata = 'nodata';
        const doc_type = 'list';
        const aid = '';
        const to_url = '';

        handleCallNewsApp(nodata, doc_type, aid, to_url);
    };

    render() {
        return (
            <div className={styles.bottomSlide} onClick={this.callupApp}>
                <span className={styles.logo} />
                <span className={styles.botton}>打开</span>
            </div>
        );
    }
}

export default errorBoundary(BottomSlide);
