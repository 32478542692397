import React from 'react';
import ChipEdit from 'ChipEdit';
import transform from 'chipDataTransform';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { h5PageCount } from 'ThreeTerminal_utils';
import Content from './content';

class Layout extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };
    componentDidMount() {
        const { content } = this.props;

        if (content.ishare) {
            h5PageCount([
                {
                    behavior: 'page',
                    type: 'other',
                    other: {
                        id: '404_page',
                        ref: encodeURIComponent(document.referrer),
                        url: encodeURIComponent(location.href.split('?')[0]),
                    },
                },
            ]);
        }
    }

    render() {
        const { content } = this.props;

        return (
            <div>
                <Content content={content} />
                <ChipEdit transform={transform} />
            </div>
        );
    }
}

export default errorBoundary(Layout);
