import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import styles from './index.css';

class Article extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };
    componentDidMount() {}
    render() {
        const { content } = this.props;

        return (
            <div className={styles.content}>
                <h2 className={styles.time}>{content.time}</h2>
                <p>{content.words}</p>
                <p className={styles.source}>{content.source}</p>
            </div>
        );
    }
}

export default errorBoundary(Article);
