import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { callNewsAPP } from '@ifeng-fe/container_content/common/client/utils/utils';
import styles from './index.css';
import Article from './article';
import BottomSlide from './bottomSlide';
import Chip from 'Chip';

class Content extends React.PureComponent {
    // static propTypes = {
    //     content: PropTypes.object,
    // };
    state = {
        info: {},
    };

    // componentDidMount() {
    //     const {
    //         content: { words },
    //     } = this.props;
    //     const liveMonth = new Date().getMonth() + 1 || 1;
    //     let infoObj = {};

    //     switch (liveMonth) {
    //         case 1:
    //             infoObj = words.one || {};
    //             break;
    //         case 2:
    //             infoObj = words.two || {};
    //             break;
    //         case 3:
    //             infoObj = words.three || {};
    //             break;
    //         case 4:
    //             infoObj = words.four || {};
    //             break;
    //         case 5:
    //             infoObj = words.five || {};
    //             break;
    //         case 6:
    //             infoObj = words.six || {};
    //             break;
    //         case 7:
    //             infoObj = words.seven || {};
    //             break;
    //         case 8:
    //             infoObj = words.eight || {};
    //             break;
    //         case 9:
    //             infoObj = words.nine || {};
    //             break;
    //         case 10:
    //             infoObj = words.ten || {};
    //             break;
    //         case 11:
    //             infoObj = words.eleven || {};
    //             break;
    //         case 12:
    //             infoObj = words.twelve || {};
    //             break;
    //         default:
    //             infoObj = words.one || {};
    //             break;
    //     }

    //     this.setState({
    //         info: { ...this.state.info, ...infoObj },
    //     });
    // }

    goToIfeng = () => {
        const params = {};
        const base = {};

        callNewsAPP(base, params);
    };

    render() {
        const { info } = this.state;

        return (
            <div className={styles.content}>
                <div className={styles.errorImg} />
                <p className={styles.errorWords}>对不起，该网页随风而逝...</p>
                {/* <Chip id="50054" type="struct" title="文案" groupName="文案" content={info}>
                    <Article />
                </Chip> */}
                <div className={styles.button}>
                    <a href="http://i.ifeng.com">去手机凤凰网看看</a>
                </div>
                <BottomSlide />
            </div>
        );
    }
}

export default errorBoundary(Content);
